import { lazy, Suspense, useEffect } from 'react'
import { Box, Container, Grid, GridItem } from '@chakra-ui/react'
import ContactCard from '../../components/contact-card'
import HeroBox from '../../components/hero-box'
import LoaderLogo from '../../components/loader-logo'
import PortfolioCard from '../../components/portfolio-card'
import PortraitServiceCard from '../../components/portrait-service-card'
import ProfileCard from '../../components/profile-card'
import { createSrc } from '../../services/image'

// IMAGES
import ManuImage from '../../images/team/MANU.JPG'
// DATA
import teamData from '../../data/teamData'
const [bioData] = teamData

//LAZY
const RealEstateServiceCard = lazy(() => import('../../components/real-estate-service-card'))

const HomePage = () => {
  useEffect(() => {
    const timoutID = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 300)
    return () => clearTimeout(timoutID)
  })
  return (
    <>
      <HeroBox />
      <Grid bgColor={'brand.secondary'} gap={1} maxWidth={'100vw'} overflow={'hidden'} p={1} templateColumns={{ base: '1fr', lg: 'repeat(3, minmax(0, 1fr))' }}>
        {/* TODO: Map over set of portfolio items */}
        <GridItem w='100%'>
          <PortfolioCard
            bgPos={{ base: 'top center', md: 'center top', lg: 'center top' }}
            image={createSrc('951b954e4d386013f456c5811b35ae5b.jpg', 500, 500)}
            text='PORTRAIT'
            textJustify={{ base: 'center', lg: 'flex-start' }}
            to={'/portfolio/portrait'}
          ></PortfolioCard>
        </GridItem>
        <GridItem w='100%'>
          <PortfolioCard
            bgPos={{ base: 'center center', md: 'center center' }}
            image={createSrc('394514e8c825eeee6ccd4e6b912f45b3.jpeg', 500, 500)}
            text='RESIDENTIAL'
            textJustify={{ base: 'center', lg: 'flex-start' }}
            to={'/portfolio/residential'}
          ></PortfolioCard>
        </GridItem>

        <GridItem w='100%'>
          <PortfolioCard
            bgPos={{
              base: 'center bottom',
              md: 'bottom center',
              lg: 'center bottom'
            }}
            image={createSrc('2e886b7df2cea350e6ac12cd20323ad7.JPEG', 500, 500)}
            text='ART'
            textJustify={{ base: 'center', lg: 'flex-start' }}
            to={'/portfolio/art'}
          ></PortfolioCard>
        </GridItem>
      </Grid>
      <Container maxW={'90vw'}>
        <Box mb={'80px'} />
        <Suspense fallback={<LoaderLogo />}>
          <RealEstateServiceCard></RealEstateServiceCard>
        </Suspense>
        {/* <Box mb={40} />
        <VirtualStagingServiceCard></VirtualStagingServiceCard> */}
        <Box mb={'280px'} />
        <PortraitServiceCard></PortraitServiceCard>

        <Box mb={'280px'} />
        <ContactCard showTitle={true} />
        <Box mb={'280px'} />

        <ProfileCard profile={bioData} profileImage={ManuImage} />
      </Container>
    </>
  )
}

export default HomePage
